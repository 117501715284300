import AboutUsImg from "../../../assets/aboutUs.jpg";
import "./about-us.scss";
import {Link} from 'react-router-dom';

function AboutUs() {
    return(
        <section className="about-us">
            <div className="about-us__container container">
                <div className="about-us__wrap row">
                    <div className="about-us__col p-0 col-lg-6 col-md-6 col-sm-12">
                        <h2 className="about-us__title">О нас</h2>
                        <p className="about-us__desc">Мы организованы, чтобы решить любую проблему, связанную с вашим автомобилем, в вашу пользу...</p>
                        <Link className="about-us__btn" to="/about">Подробные</Link>
                    </div>
                    <div className="about-us__col p-0 ps-3 col-lg-6 col-md-6 col-sm-12">
                        <img className="about-us__img" src={AboutUsImg} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUs