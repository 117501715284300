function Card({item}) {
    return(
        <li className="services__item col-lg-3 col-sm-6 col-md-4">
            <div className="services__card">
                <img className="services__img" src={item.img} alt="" />
                <h3 className="services__item--title">{item.text}</h3>
            </div>
        </li>
    )
}

export default Card