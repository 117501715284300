import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Card from "../../../Components/Card/Card";
import Data from "../../../Data/data";
import "./Services.scss";

function Services() {
    const [responsive,setResponsive] = useState(false);
    const onlyData = Data.slice(0,4);
    useEffect(()=> {
        if(window.innerWidth < 578){
            setResponsive(true)
        }
        else{
            setResponsive(false)
        }
    },[])
    
    window.addEventListener('resize', ()=> {
        if(window.innerWidth < 578){
            setResponsive(true)
        }
        else{
            setResponsive(false)
        }
    })

    return(
        <section className="services">
            <div className="services__container container">
                <h2 className="services__title">Услуги автоэлектрика</h2>
                <ul className="services__list row list-unstyled m-0 p-0">
                    {
                        responsive ? 
                        onlyData.map(item => (
                            <Card key={item.id} item={item}/>
                        )) : 
                        Data.map(item => (
                            <Card key={item.id} item={item}/>
                        )) 
                    }
                </ul>
                <a href="tel:+998909953399" className="services__btn">Вызвать мастера</a>
                <button onClick={()=> setResponsive(false)} className="services__show--btn">Ещё</button>
            </div>
        </section>
    )
}

export default Services;