import "./contact-us.scss";
import Map from "../../../assets/map.jpg"

function ContactUs() {
    return(
        <section className="contact-us">
            <div className="contact-us__container container">
                <div className="contact-us__row row">
                    <div className="contact-us__col col-lg-4">
                        <h2 className="contact-us__title">Контакты</h2>
                        <ul className="contact-us__list list-unstyled p-0 m-0">
                            <li className="contact-us__item">
                                <span className="contact-us__sub--title">Электронное почты</span>
                                <a className="contact-us__link" href="mailto:sohibsharipov000@gmail.com">sohibsharipov000@gmail.com</a>
                            </li>
                            <li className="contact-us__item">
                                <span className="contact-us__sub--title">Телефонь</span>
                                <a className="contact-us__link" href="tel:+998909953399">+998 90 995 33 99</a>
                            </li>
                            <li className="contact-us__item">
                                <span className="contact-us__sub--title">Адрес</span>
                                <span className="contact-us__link" >Город Ташкент: улица Шахрисабз: 56 </span>
                            </li>
                            <li className="contact-us__item">
                                <span className="contact-us__sub--title">Ежедневно</span>
                                <p className="contact-us__link" >Мы всегда к вашим услугам 7/24. Вы можете связаться с нами в любое время</p>
                            </li>
                        </ul>
                    </div>
                    <div className="contact-us__col col-lg-8">
                        <div  className="contact-us__map" onClick={goToMap}>
                            <img className="contact-us__map--img" src={Map} alt="map" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function goToMap(e) {
    e.preventDefault();
    window.location.href = "https://goo.gl/maps/ZJ1WnWsizhJkwM6U8";
}

export default ContactUs;