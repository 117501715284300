import React, {useState} from 'react';
import Logo from "../../assets/logo.svg";
import {Link} from 'react-router-dom';
import {GrClose} from "react-icons/gr";
import {GiHamburgerMenu} from "react-icons/gi";
import "./Header.scss";
import "./Nav.scss";


function Header() {
    const [nav, setNav] = useState(false);
    window.addEventListener("click", function (e) {
        if (!e.target.closest(".nav") && !e.target.closest(".header__burger--btn")) {
            setNav(false)
        }
    })
    return (
        <header className='header'>
            <div className='header__contaiener container'>
                <div className='header__wrap d-flex '>
                    <Link className='header__logo--link' to='/'>
                        <img className='header__img' src={Logo} alt='logo'/>
                    </Link>
                    <nav className={nav ? `navbar__open header__nav nav` : "header__nav nav"}>
                        <button onClick={() => setNav(false)} className='nav__x-btn'><GrClose/></button>
                        <ul className='nav__list d-flex' style={nav ? {alignItems: "flex-end"} : null}>
                            <li className='nav__item'>
                                <Link onClick={goToSectionService} className='nav__link' to="/">Услуги</Link>
                            </li>
                            <li className='nav__item'>
                                <Link onClick={() => {
                                    setNav(false);
                                    goToTop()
                                }} className='nav__link' to="/about">О нас</Link>
                            </li>
                            <li className='nav__item'>
                                <Link onClick={() => {
                                    setNav(false);
                                    goToTop()
                                }} className='nav__link' to="/contact">Контакты</Link>
                            </li>
                            <li className='nav__item'>
                                <a href="tel:+998909953399" className='nav__link'>Вызвать мастера</a>
                            </li>
                        </ul>
                    </nav>
                    <button style={nav ? {opacity: "0", zIndex: "-1"} : null} onClick={() => setNav(true)}
                            className='header__burger--btn' id='burgerBtn'><GiHamburgerMenu/></button>
                </div>
            </div>
        </header>

    )
}

function goToSectionService() {
    document.querySelector(".services").scrollIntoView({behavior: "smooth"})
}

function goToTop() {
    // document.querySelector("header").scrollTo({behavior: "smooth"});
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
    })
    debugger
}

function hideNavbar() {

}

window.addEventListener("scroll", function (e) {
    if (window.scrollY > 100) {
        document.querySelector("header").classList.add("header__fixed");
        document.querySelector("body").classList.add("hero__move--top")
    } else {
        document.querySelector("header").classList.remove("header__fixed")
        document.querySelector("body").classList.remove("hero__move--top")
    }
})

export default Header;
