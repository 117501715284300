import { Link } from "react-router-dom";
import Logo from "../../assets/logo.svg";
import Telegram from "../../assets/telegram.svg";
import Instagram from "../../assets/instagram.svg";
import Facebook from "../../assets/facebook.svg";
import call from "../../assets/call.svg"
import "./Footer.scss"

function Footer() {
    return(

        <footer className="footer">
            <div className="footer__container container">
                <div className="footer__wrap">
                    <ul className="footer__list row list-unstyled p-0 m-0">
                        <li className="footer__item p-0">
                            <Link className="footer__logo--link" to="/" >
                                <img className="footer__logo" src={Logo} alt="logo" />
                            </Link>
                        </li>
                        <li className="footer__item p-0">
                            <h3 className="footer__title">Компания</h3>
                            <ul className="footer__item--list list-unstyled m-0 p-0">
                                <li className="footer__item--links">
                                    <Link to="/about" onClick={goToTop} className="footer__item--link" >О нас</Link>
                                    <Link onClick={goToSectionSeason} className="footer__item--link" to="/">Почему мы?</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="footer__item p-0">
                            <h3 className="footer__title">Контакты</h3>
                            <ul className="footer__item--list list-unstyled m-0 p-0">
                                <li className="footer__item--links d-flex flex-column">
                                    <span className="footer__item--link--subtitle" >Электронное почты</span>
                                    <a href="mailto:javoxir9953399@gmail.com" className="footer__item--link" >javoxir9953399@gmail.com</a>
                                </li>
                                <li className="footer__item--links d-flex flex-column">
                                    <span className="footer__item--link--subtitle" >Телефонь</span>
                                    <a href="tel:+998909953399" className="footer__item--link" >+998 90 995 33 99</a>
                                </li>
                                <li className="footer__item--links d-flex flex-column">
                                    <span className="footer__item--link--subtitle" >Адрес</span>
                                    <span className="footer__item--link" >Город Ташкент: улица Шахрисабз: 56</span>
                                </li>
                            </ul>
                        </li>
                        <li className="footer__item p-0">
                            <h3 className="footer__title">Вызов мастера</h3>
                            <p className="footer__desc">Сломалась ли ваша машина в дороге или дома Если нет возможности пойти в мастерскую Тогда наши специалисты помогут вам. Все что вам нужно сделать, это позвонить.</p>
                            <a href="tel:+998909953399" className="footer__btn">На выезд</a>
                        </li>
                        <li className="footer__item p-0">
                            <h3 className="footer__title">Социальные сети</h3>
                            <ul className="footer__item--list list-unstyled m-0 p-0">
                                <li className="footer__item--links d-flex align-items-center">
                                    <a className="footer__link" href="https://t.me/avtomaster24_uz"><img className="me-1" src={Telegram} alt="telegram" /> Telegram</a>
                                </li>
                                <li className="footer__item--links d-flex align-items-center">
                                    <a className="footer__link" href="#"><img className="me-1" src={Instagram} alt="instagram" /> Instagram</a>
                                </li>
                                <li className="footer__item--links d-flex align-items-center">
                                    <a className="footer__link" href="#"><img className="me-1" src={Facebook} alt="" /> Facebook</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="container">
                <div className="footer__bottom d-flex align-items-center mb-2">
                    <span className="footer__copyrigth">Copyright R 2022</span>
                    <h3 className="footer__bottom--title">Created by <span>Sages group</span></h3>
                </div>
            </div>
            <a className="fixed__btn" href="tel:+998909953399">
                <img src={call} alt=""/>
            </a>
        </footer>

    )
}

function goToSectionSeason(){
    document.querySelector(`.season`).scrollIntoView({behavior:"smooth"})
}

function goToTop() {
    // document.querySelector("header").scrollTo({behavior: "smooth"});
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
    })
    debugger
}

export default Footer;