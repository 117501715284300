import serviesImg1 from "../assets/services-img1.jpg";
import serviesImg2 from "../assets/services-img2.jpg";
import serviesImg3 from "../assets/services-img3.jpg";
import serviesImg4 from "../assets/services-img4.jpg";
import serviesImg5 from "../assets/services-img5.jpg";
import serviesImg6 from "../assets/services-img6.jpg";
import serviesImg7 from "../assets/services-img7.jpg";
import serviesImg8 from "../assets/services-img8.jpg";
import serviesImg9 from "../assets/services-img9.jpg";
import serviesImg10 from "../assets/services-img10.jpg";
import serviesImg11 from "../assets/services-img11.jpg";
import serviesImg12 from "../assets/services-img12.jpg";

const Data = [
    {
        id:1,
        img:serviesImg1,
        text:"Замена Бензонасоса",
    },
    {
        id:2,
        img:serviesImg2,
        text:"Вулканизация выездной",
    },
    {
        id:3,
        img:serviesImg3,
        text:"Прикурить Аккумулятор",
    },
    {
        id:4,
        img:serviesImg4,
        text:"Компьютерная Диагностика",
    },
    {
        id:5,
        img:serviesImg5,
        text:"Ремонт Генератора",
    },
    {
        id:6,
        img:serviesImg6,
        text:"Вскрыть автомобиль",
    },
    {
        id:7,
        img:serviesImg7,
        text:"Установка магнитафонь",
    },
    {
        id:8,
        img:serviesImg8,
        text:"Ремонт Замок Зажигания",
    },
    {
        id:9,
        img:serviesImg9,
        text:"Ремонт стартер",
    },
    {
        id:10,
        img:serviesImg10,
        text:"Aвтомобил фарa замена",
    },
    {
        id:11,
        img:serviesImg11,
        text:"Ремонт дворника",
    },
    {
        id:12,
        img:serviesImg12,
        text:"Замена печки радиатора",
    },
]

export default Data;