import React from 'react';
import AboutUs from './AboutUs/AboutUs';
import ContactUs from './ContactUs/ContactUs';
import Hero from './Hero/Hero';
import Season from './Season/Season';
import Services from './Services/Services';

function Home() {
  return (
    <>
      <Hero />
      <Services />
      <Season />
      <AboutUs />
      <ContactUs />
    </>
  )
}

export default Home
