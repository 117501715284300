import {Link} from "react-router-dom";
import "./Contact.scss";

function Contact() {
    return (
        <section className="contact mb-4">
            <div className="contact__container container">


                <div className="contact__row row">

                    <div className="contact__pagentaion">
                        <Link to="/">Home</Link>|
                        <span className="contact__pagenatoion--active">Контакты</span>
                    </div>

                    <h2 className="contact__title p-0">
                        Контакты
                    </h2>
                    <h3 className="contact__subtitle p-0">
                        Свяжитесь с нами в любое время суток без каких-либо неудобств по номерам ниже и воспользуйтесь
                        нашими услугами.
                    </h3>
                    <ul className="contact__list list-unstyled p-0 m-0">
                        <li className="contact__item">
                            <span className="contact__sub--title">Электронное почты</span>
                            <a className="contact__link"
                               href="mailto:javoxir9953399@gmail.com">javoxir9953399@gmail.com</a>
                        </li>
                        <li className="contact__item">
                            <span className="contact__sub--title">Телефонь</span>
                            <a className="contact__link" href="tel:998909953399">+999890 995 33 99</a>
                        </li>
                        <li className="contact__item">
                            <span className="contact__sub--title">Адрес</span>
                            <span className="contact__link">Город Ташкент: улица Шахрисабз: 56 </span>
                        </li>
                        <li className="contact__item">
                            <span className="contact__sub--title">Ежедневно</span>
                            <p className="contact__link">Мы всегда к вашим услугам 7/24. Вы можете связаться с нами в
                                любое время</p>
                        </li>
                    </ul>

                </div>
            </div>
        </section>
    )
}

export default Contact;