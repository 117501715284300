import "./Hero.scss";
import { Link } from "react-router-dom";

function Hero() {
    return(
        <section className="hero">
            <div className="hero__container container">
                <h2 className="hero__title">АВТОМАСТЕР НА ВЫЕЗД ПО ТАШКЕНТУ</h2>
                <p className="hero__desc">Автосервис всегда рядом с вами</p>
                <a  className="hero__btn" href="tel:+998909953399">Вызвать мастера</a>
            </div>
        </section>
    )
    
}

export default Hero;