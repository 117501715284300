import "./Season.scss"

function Season() {
    return(
        <section className="season">
            <div className="season__container container">
                <div className="season__wrap row">
                    <div className="col-lg-6 col-sm-12">
                        <h2 className="season__title">Почему мы?</h2>
                        <p className="season__desc">Наши услуги не выбирают сезон, время и местонахождение заказчика</p>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <div className="season__card--wrap d-flex">
                            <div className="season__card--block">
                                <div className="season__card">
                                    <h2 className="season__card--title">7/24 работа</h2>
                                    <p className="season__card--desc">Мы всегда к вашим услугам 7/24. Вы можете связаться с нами в любое время</p>
                                </div>
                                <div className="season__card">
                                    <h2 className="season__card--title">Форма оплата  любая</h2>
                                    <p className="season__card--desc">Теперь даже ваши проблемы с оплатой не могут ограничить вас</p>
                                </div>
                            </div>
                            <div className="season__card--block">
                                <div className="season__card">
                                    <h2 className="season__card--title">Помочь на дороге</h2>
                                    <p className="season__card--desc">В любом случае наши профессиональные мастера с вами</p>
                                </div>
                                <div className="season__card">
                                    <h2 className="season__card--title">Выгодные цены</h2>
                                    <p className="season__card--desc">Приемлемая цена наших услуг не оставит вас в неудобном положении</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Season;