import { Link } from "react-router-dom";
import AboutImg from "../../assets/about.jpg";
import "./About.scss";

function About() {
    return(
        <section className="about mb-2">
            <div className="about__container container">
                <div className="about__pagentaion">
                    <Link to="/">Home</Link>|
                    <span className="about__pagenatoion--active">О нас</span>
                </div>
                    <div className="about__wrap">
                        <h3 className="about__title">О нас</h3>
                        <p className="about__desc">Мы организованы, чтобы решить любую проблему, связанную с вашим автомобилем, в вашу пользу. Теперь мы к вашим услугам день и ночь с новым контентом, широким ассортиментом, удобными функциями и неограниченным удобством</p>
                        <div className="col-sm-10">
                            <img className="about__img" src={AboutImg} alt="" />
                        </div>
                        <p className="about__desc">С нашей командой мы предоставляем качественные и надежные услуги нашим уважаемым клиентам только в автономном режиме. Но мы запустили собственный веб-сайт, чтобы помочь более широкому кругу людей, найти решения для большего количества проблем, и мы расширили объем и вес наших услуг.</p>
                        <p className="about__desc">Теперь вы можете починить сломанную машину в дороге всего за 1 звонок. Или вам не нужно отдельно искать запчасти и мастеров для вашего автомобиля, нуждающегося в ремонте, ведь все согласовано в наших сервисах. </p>
                    </div>
                </div>
        </section>
    )
}

export default About;